@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Red+Hat+Display:wght@300&display=swap');

.main{
    background-color:  whitesmoke ;
    height: 60vh;
}

.main-text{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.flores{
    background-image: url("../img/Mainimage.png");
    background-size: cover;
    min-height: 610px;
}