@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.header{
    font-family: 'Great Vibes', cursive;
    display: flex;
    font-size:80px;
    width: calc(100vw);
    height: calc(35
    vh);
    align-items: center;
    justify-content: center;
    background:  #fbdcdc;
    color: #3e4f2f ;
}

@media only screen and (max-width: 600px){
    .header{
        font-size:50px;
    }
}

.header-main{
    margin: 10px;
}

.header-heart-icon{
    
    font-size:large;
    color: #3e4f2f ;
}

@media  {
    
}